.footer {
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;   
    width: 100%;
    height: 80px;

}

.footer > p {
    text-align: center;
}
  
/* mobile view */

@media only screen and (max-width: 850px) {
    .footer {
        width: 80%;
    }
}