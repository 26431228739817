@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway&display=swap);
body {

  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  /* width: 100vw; */

}

html, body {
  max-width: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.home-main {
    z-index: 3;
    position: relative;    
    margin: none;
    padding: 0;
    width: 100%;
    height: 100%;
    background-color: black;
}

.home-video {
    position: absolute;
    width: 100%;
    height: 100%;
}

.welcome-title {
    position: absolute;
    text-decoration: none;
    margin-left: 20px;
    font-weight: 100;
    letter-spacing: .5px;
    transition: 1s;
}

.welcome-title :hover {
    color: azure;
    transition: 1s;
}



.welcome-title > h2 {
    font-weight: 50;
    font-size: 2.5rem;
    color: rgb(71, 71, 71);
}

.welcome-title > h2 :hover {
    font-weight: 50;
    font-size: 2.5rem;
    color: grey;
}

.home-copyright {
    width: 95%;
    position: absolute;
    bottom: 0;
}

.copyright-link {
    text-decoration: none;
    color: white;
}

.copyright-link > h4 {
    text-align: center;
    width: 190px;
    background-color: rgba(134, 127, 127, 0.5);
    font-family: arial;
    font-weight: 200; 
}

.contact-main {
    position: abolute;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    top: 60px;
    margin: none;
    padding: 0;
    max-width: 100%;
    overflow-x: hidden;
}


.contact-background {
    width: 100vw;
    height: 100vh;
    min-width: 500px;
    background-repeat: no-repeat;
    background-attachment: fixed;

}

.contact-content {
    position: absolute;
    top: 70vh;
    z-index: 1;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    max-width: 1000px;
    color: whitesmoke;
    background-color: rgb(49, 46, 41);

}

#form-button {
    margin-top: 15px;
}

.contact-form {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    width: 500px;
    height: 400px;
}

.contact-content > h1 {
    font-size: 1.8rem;
    font-weight: 100;
    margin: 30px 0 60px 0;
}

@media only screen and (max-width: 850px) {
    .contact-form {
        width: 80%;
        max-width: 400px;
    }
}
.credits {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;    width: 100%;
    height: 50px;
    text-align: end;
    margin: 40px 20px 20px 0;
}
  
.credits > p {
margin: 2px 0 0 0;
}


.page-main {
    position: abolute;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    top: 60px;
    margin: none;
    padding: 0;
    max-width: 100%;
}

.page-background {
    width: 100vw;
    height: 100vh;
    min-width: 400px;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.page-content {
    position: absolute;
    top: 70vh;
    z-index: 1;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    color: black;
}

.page-written {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    max-width: 1030px;
    background-color: white;
}


.page-left {
    width: 50%;
    margin-top: 30px;
    padding: 0 50px;
    color: black;
}

.page-left > h1 {
    font-size: 1.4rem;
    font-weight: 100;
    margin-bottom: 60px;
}

.page-left > p {
    font-size: 0.85rem;
    font-weight: 50;
    line-height: 20px;
} 

.page-right {
    width: 50%;
    margin-top: 30px;
    padding: 0 50px;
    color: black;
}

.page-right > h1 {
    font-size: 1.4rem;
    font-weight: 100;
    margin-bottom: 60px;
}

.page-right > p {
    font-size: 0.85rem;
    font-weight: 50;
    line-height: 20px;
} 

.page-grid {
    margin: 40px 0;
    height: 90%;
    width: 100vw;
    max-width: 1000px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.page-footer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    max-width: 1030px;
    background-color: white;
}

/* mobile view */

@media only screen and (max-width: 450px) {


    .page-written {
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .page-left {
        width: 80%;
        padding: 0 30px;
    }

    .page-right {
        width: 80%;
        padding: 0 30px;
    }
}



.grid-links {
    text-decoration: none;
    color: whitesmoke;
}

.grid-box {
    position: relative;
    width: 400px;
    height: 300px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-decoration: none;
    /* -webkit-transition: .3s;
    transition: .3s; */
}

.grid-image {
    position: absolute;
    width: 100%;
    height: 100%;
}

.grid-title {
    position: absolute;
    height: 15px;
    width: 85%;
    bottom: 15px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    background: rgba(0,0,0,0.5);
}

.grid-title > h5 {
    font-size: 0.65rem;
    margin: 0;
    font-weight: 100;
    color: white;
}
.footer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center; 
    -webkit-justify-content: center; 
            justify-content: center;   
    width: 100%;
    height: 80px;

}

.footer > p {
    text-align: center;
}
  
/* mobile view */

@media only screen and (max-width: 850px) {
    .footer {
        width: 80%;
    }
}
.App {
  font-family: Raleway;
  width: 100%;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
}

.flex {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.flex-col {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0 20px;
}

li {
  font-size: 0.85rem;
  font-weight: 50;
  line-height: 20px;
  margin: 10px 0;
}


a {
  color: black;
}

/* breaking form */

.breakingForm-book-image {
  margin: 10px 0;
  width: 100%;
}

.squareFoot-book-image {
  margin: 10px 0;
  width: 100%;
  border: 0.5px solid rgb(107, 107, 107);
}


/* actor movement */

.actorMovement-book-image {
  margin-top: 50px;
  width: 100%;
}



.fade-appear,
.fade-enter {
opacity: 0;
z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
opacity: 1;
transition: opacity 450ms linear 250ms;
}
.fade-exit {
opacity: 1;
}
.fade-exit.fade-exit-active {
opacity: 0;
transition: opacity 150ms linear;
}

.navbar-main-pages {
    position: absolute;
    z-index: 2;
    top: 10;
    right: 3;
    font-family: Raleway;
    height: 7vh;
    width: 80vw;
    max-width: 1000px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    background: none;
    color: whitesmoke
}

.navbar-title-pages {
    margin-left: 10px;
    text-decoration: none;
    /* color: whitesmoke; */
    font-weight: 100;
    letter-spacing: .5px;
    transition: .3s;
}


.large-font {
    font-size: 1.5rem;
}

.med-font {
    font-size: 0.9rem;
}

.left-margin {
    margin-left: 20px;
}

.navbar-list-home {
    margin-right: 10px;
    width: 30vw;
    max-width: 500px;
    height: 10vh;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    list-style: none;
    transition: .3s;
}

.navbar-item-pages > h2 {
    font-weight: 100;
}

.navbar-list-pages {
    margin-right: 20px;
    width: 70vw;
    max-width: 650px;
    height: 10vh;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    list-style: none;
    transition: .3s;
}


.navbar-mobile {
    position: absolute;
    z-index: 2;
    top: 10;
    right: 7;
    height: 7vh;
    width: 98vw;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    background: none;
    color: whitesmoke;
    padding-right: 40px;
}

.navbar-menu {
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 7vh;
    width: 50%;
    max-width: 200px;
    background: rgba(77, 102, 128, 0.6);
    margin: none;
}

.navbar-menu-list {
    padding-left: 0;
    padding-right: 10px;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    -webkit-flex-direction: column;
            flex-direction: column;
    list-style: none;
    max-width: 200px;
}

@media only screen and (min-width: 850px) {
    .navbar-mobile {
        display: none;
    }
}

@media only screen and (max-width: 850px) {
    .navbar-main-pages {
        display: none;
    }
}

.navbar-menu-item {
    font-size: 1rem;
    text-decoration: none;
    letter-spacing: 0.5px;

}

.navbar-menu-item:hover {
    font-size: 0.9rem;
    transition: .3s;
}


.navbar-item {
    font-size: 0.8rem;
    color: black;
    text-decoration: none;
    letter-spacing: 0.5px;
    transition: ease-in .5s;
    cursor: pointer;
}

.navbar-item-pages {
    font-size: 0.8rem;
    color: whitesmoke;
    text-decoration: none;
    letter-spacing: 0.5px;
    transition: ease-in .5s;
    cursor: pointer;
}

.navbar-item:hover {
    text-decoration: underline;
    transition: ease-out .5s;
}

.hide-title {
    font-size: 0.1rem;
    visibility: hidden;
    transition: .3s;

}

.hide-menu {
    font-size: 0.1rem;
    visibility: hidden;
    /* -webkit-transition: .1s;
    transition: .1s; */

}

