@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');


.App {
  font-family: Raleway;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0 20px;
}

li {
  font-size: 0.85rem;
  font-weight: 50;
  line-height: 20px;
  margin: 10px 0;
}


a {
  color: black;
}

/* breaking form */

.breakingForm-book-image {
  margin: 10px 0;
  width: 100%;
}

.squareFoot-book-image {
  margin: 10px 0;
  width: 100%;
  border: 0.5px solid rgb(107, 107, 107);
}


/* actor movement */

.actorMovement-book-image {
  margin-top: 50px;
  width: 100%;
}



.fade-appear,
.fade-enter {
opacity: 0;
z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
opacity: 1;
transition: opacity 450ms linear 250ms;
}
.fade-exit {
opacity: 1;
}
.fade-exit.fade-exit-active {
opacity: 0;
transition: opacity 150ms linear;
}
