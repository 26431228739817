.credits {
    display: flex;
    flex-direction: column;
    align-items: center;    width: 100%;
    height: 50px;
    text-align: end;
    margin: 40px 20px 20px 0;
}
  
.credits > p {
margin: 2px 0 0 0;
}

