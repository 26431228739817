.grid-links {
    text-decoration: none;
    color: whitesmoke;
}

.grid-box {
    position: relative;
    width: 400px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    /* -webkit-transition: .3s;
    transition: .3s; */
}

.grid-image {
    position: absolute;
    width: 100%;
    height: 100%;
}

.grid-title {
    position: absolute;
    height: 15px;
    width: 85%;
    bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,0.5);
}

.grid-title > h5 {
    font-size: 0.65rem;
    margin: 0;
    font-weight: 100;
    color: white;
}