.contact-main {
    position: abolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    top: 60px;
    margin: none;
    padding: 0;
    max-width: 100%;
    overflow-x: hidden;
}


.contact-background {
    width: 100vw;
    height: 100vh;
    min-width: 500px;
    background-repeat: no-repeat;
    background-attachment: fixed;

}

.contact-content {
    position: absolute;
    top: 70vh;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 1000px;
    color: whitesmoke;
    background-color: rgb(49, 46, 41);

}

#form-button {
    margin-top: 15px;
}

.contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 500px;
    height: 400px;
}

.contact-content > h1 {
    font-size: 1.8rem;
    font-weight: 100;
    margin: 30px 0 60px 0;
}

@media only screen and (max-width: 850px) {
    .contact-form {
        width: 80%;
        max-width: 400px;
    }
}